// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("/Users/Julien/public_html/shogun.io/landing/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("/Users/Julien/public_html/shogun.io/landing/src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("/Users/Julien/public_html/shogun.io/landing/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("/Users/Julien/public_html/shogun.io/landing/src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-register-tsx": () => import("/Users/Julien/public_html/shogun.io/landing/src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/Julien/public_html/shogun.io/landing/.cache/data.json")

